/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
function gform2_uncheck_all(ul_parent) {
  jQuery('#' + ul_parent + ' .checklabel').each(function(){
      jQuery( this ).removeClass( 'checked' );
      jQuery( this ).next().attr( 'checked', false );
  }); 
}
function gform2_labels() {
  jQuery('#gform_2 input:radio').each(function(){
      jQuery( '<label class="checklabel"></label>' ).insertBefore( this );
      jQuery( this ).hide();
  }); 

  jQuery('.gfield_label').each( function(){
    jQuery( this ).wrapInner('<h2></h2>');
  });
  jQuery('.gfield_description').each( function(){
    jQuery( this ).wrapInner('<h3></h3>');
  });

  jQuery( '.checklabel' ).on( 'click touch', function(){
    var ul_parent = jQuery( this ).parent().parent().attr('id');
    gform2_uncheck_all(ul_parent);
    jQuery( this ).addClass( 'checked' );
    jQuery( this ).next().attr( "checked", true );
  });

  jQuery( '.gfield_radio label:not(.checklabel)' ).on( 'click touch', function(){
    gform2_uncheck_all();
    jQuery( this ).prev().prev().addClass( 'checked' );
    jQuery( this ).prev().attr( "checked", true );
  });
  jQuery( '#gf_page_steps_2' ).detach().appendTo( '#scroll-link-1' );
}

// jQuery formatted selector to search for focusable items
var focusableElementsString = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";

// store the item that has focus before opening the modal window
var focusedElementBeforeModal;

jQuery(document).ready(function(){
  jQuery("a#en-savoir-plus").fancybox({
     'padding': 30,
     'scrolling': 'yes'
  })
});

jQuery(window).on('resize load', function(){

  if (jQuery(window).width() < 100)
  {
    jQuery( 'a#en-savoir-plus' ).addClass( 'nofancybox' ).attr( 'href', '/en-savoir-plus/' );
  }
  else
  {
    jQuery( 'a#en-savoir-plus' ).removeClass('nofancybox').attr('href', '#en-savoir-content');
  }

});

function trapEscapeKey(obj, evt) {

    // if escape pressed
    if (evt.which === 27) {

        // get list of all children elements in given object
        var o = obj.find('*');

        // get list of focusable items
        var cancelElement;
        cancelElement = o.filter("#cancel");

        // close the modal window
        cancelElement.click();
        evt.preventDefault();
    }

}

function trapTabKey(obj, evt) {

    // if tab or shift-tab pressed
    if (evt.which === 9) {

        // get list of all children elements in given object
        var o = obj.find('*');

        // get list of focusable items
        var focusableItems;
        focusableItems = o.filter(focusableElementsString).filter(':visible');

        // get currently focused item
        var focusedItem;
        focusedItem = jQuery(':focus');

        // get the number of focusable items
        var numberOfFocusableItems;
        numberOfFocusableItems = focusableItems.length;

        // get the index of the currently focused item
        var focusedItemIndex;
        focusedItemIndex = focusableItems.index(focusedItem);

        if (evt.shiftKey) {
            //back tab
            // if focused on first item and user preses back-tab, go to the last focusable item
            if (focusedItemIndex === 0) {
                focusableItems.get(numberOfFocusableItems - 1).focus();
                evt.preventDefault();
            }

        } else {
            //forward tab
            // if focused on the last item and user preses tab, go to the first focusable item
            if (focusedItemIndex === numberOfFocusableItems - 1) {
                focusableItems.get(0).focus();
                evt.preventDefault();
            }
        }
    }

}

function setInitialFocusModal(obj) {
    // get list of all children elements in given object
    var o = obj.find('*');

    // set focus to first focusable item
    var focusableItems;
    focusableItems = o.filter(focusableElementsString).filter(':visible').first().focus();

}

function hideModal() {
    jQuery('#modalOverlay').css('display', 'none'); // remove the overlay in order to make the main screen available again
    jQuery('#modal').css('display', 'none'); // hide the modal window
    jQuery('#modal').attr('aria-hidden', 'true'); // mark the modal window as hidden
    jQuery('#mainPage').attr('aria-hidden', 'false'); // mark the main page as visible

    var iframe = document.querySelector( 'iframe');
	  var video = document.querySelector( 'video' );
    if ( iframe ) {
      var iframeSrc = iframe.src;
      iframe.src = iframeSrc;
    }
    if ( video ) {
      video.pause();
    }

    // set focus back to element that had it before the modal was opened
    focusedElementBeforeModal.focus();
}

function enterButtonModal() {
    // BEGIN logic for executing the Enter button action for the modal window
    alert('form submitted');
    // END logic for executing the Enter button action for the modal window
    hideModal();
}

function setFocusToFirstItemInModal(obj){
    // get list of all children elements in given object
    var o = obj.find('*');

    // set the focus to the first keyboard focusable item
    o.filter(focusableElementsString).filter(':visible').first().focus();
}

function showModal(obj) {
    jQuery('#mainPage').attr('aria-hidden', 'true'); // mark the main page as hidden
    jQuery('#modalOverlay').css('display', 'block'); // insert an overlay to prevent clicking and make a visual change to indicate the main apge is not available
    jQuery('#modal').css('display', 'block'); // make the modal window visible
    jQuery('#modal').attr('aria-hidden', 'false'); // mark the modal window as visible

    // attach a listener to redirect the tab to the modal window if the user somehow gets out of the modal window
    jQuery('body').on('focusin','#mainPage',function() {
        setFocusToFirstItemInModal(jQuery('#modal'));
    });

    // save current focus
    focusedElementBeforeModal = jQuery(':focus');

    setFocusToFirstItemInModal(obj);
}

function langmenu() {
  jQuery( '.nav-lang' ).toggleClass('open');
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.main')
          .find('img')
          .addClass('img-responsive');

        $('#startModal').click(function(e) {
            showModal($('#modal'));
        });
        $('#cancel').click(function(e) {
            hideModal();
        });
        $('#cancelButton').click(function(e) {
            hideModal();
        });
        $('#enter').click(function(e) {
            enterButtonModal();
        });
        $('#modalCloseButton').click(function(e) {
            hideModal();
        });
        $('#modal').keydown(function(event) {
            trapTabKey($(this), event);
        });
        $('#modal').keydown(function(event) {
            trapEscapeKey($(this), event);
        });
        $( '.nav-lang-ico' ).on( 'click touch', function(){
          $( '.nav-lang' ).toggleClass('open');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Quiz page, note the change from se-tester (body class) to se_tester.
    'page_id_192': {
      init: function() {
        gform2_labels();

        $(document).bind('gform_post_render', function(){
          gform2_labels();
        });

        $(document).on('gform_page_loaded', function(event, form_id, current_page){
          switch( current_page ) {
            case '1':
              $( '#quizimage' ).attr("src","//d8bm5skh1jz0q.cloudfront.net/bacardi/wp-content/uploads/2016/07/15103417/resultat.jpg");
              break;
            case '2':
              $( '#quizimage' ).attr("src","//d8bm5skh1jz0q.cloudfront.net/bacardi/wp-content/uploads/2016/07/15102752/q2.jpg");
              break;
            case '3':
              $( '#quizimage' ).attr("src","//d8bm5skh1jz0q.cloudfront.net/bacardi/wp-content/uploads/2016/05/31152815/quiz101.jpg");
              break;
            case '4':
              $( '#quizimage' ).attr("src","//d8bm5skh1jz0q.cloudfront.net/bacardi/wp-content/uploads/2016/05/31152807/quiz91.jpg");
              break;
            case '5':
              $( '#quizimage' ).attr("src","//d8bm5skh1jz0q.cloudfront.net/bacardi/wp-content/uploads/2016/05/31152747/quiz71.jpg");
              break;
            case '6':
              $( '#quizimage' ).attr("src","//d8bm5skh1jz0q.cloudfront.net/bacardi/wp-content/uploads/2016/07/15103126/q6.jpg");
              break;
            case '7':
              $( '#quizimage' ).attr("src","//d8bm5skh1jz0q.cloudfront.net/bacardi/wp-content/uploads/2016/05/31152735/quiz61.jpg");
              break;
            case '8':
              $( '#quizimage' ).attr("src","//d8bm5skh1jz0q.cloudfront.net/bacardi/wp-content/uploads/2016/05/31152726/quiz51.jpg");
              break;
            case '9':
              $( '#quizimage' ).attr("src","//d8bm5skh1jz0q.cloudfront.net/bacardi/wp-content/uploads/2016/05/31152703/quiz21.jpg");
              break;
            case '10':
              $( '#quizimage' ).attr("src","//d8bm5skh1jz0q.cloudfront.net/bacardi/wp-content/uploads/2016/05/31152710/quiz31.jpg");
              break;
            default:
              break;
          }
        });

        $(document).bind('gform_confirmation_loaded', function(event, formId){
          $( '#quizimage' ).attr("src","//d8bm5skh1jz0q.cloudfront.net/bacardi/wp-content/uploads/2016/07/15103417/resultat.jpg");
          $( '#scroll-link-1 .gf_page_steps' ).each( function(i){
            $( this ).remove();
          });
          $( '#scroll-link-1 .inner' ).addClass( 'completed' );
        });
      }
    },
    // 10 Clés Pour bien Déguster page
    'page_id_91': {
      init: function() {
        $(window).scroll( function(){
          $('.hidden').each( function(i){
            var top_of_object = $(this).offset().top;
            var middle_of_window = $(window).scrollTop() + ( $(window).height() / 2 );
            if( middle_of_window > top_of_object ){
              $(this).animate({'opacity':'1'},500);
            }
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    //Les Actualites page
    'category_1': {
      init: function() {
        var lis = $('li', '#posts-list');
        var delay = 0;
        lis.each(function() {
          var $li = $(this);
          $li.queue('fade', function(next) {
              $li.delay(delay).fadeIn(800, next);
          });
          
          $li.dequeue('fade');
          
          delay += 850;
        });       
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
